import React from "react"
import { Link } from "gatsby"
import style from "./menubar.module.scss"
import LogoWhite from "../../img/atia-logo-white.svg"
import { Navbar, Nav, NavbarBrand } from "react-bootstrap"
import { useState, useEffect } from "react"
const Menubar = () => {
  const [scroll] = useState(50)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 50
      if (window.outerWidth > 766) {
        if (scrollCheck < scroll) {
          document.querySelector("#topLevel").style.backgroundColor = "#272727"
        }
        if (scrollCheck === false) {
          document.querySelector("#topLevel").style.backgroundColor =
            "rgba(0,0,0,0)"
        }
      } else {
        document.querySelector("#topLevel").style.backgroundColor = "#272727"
      }
    })
  })
  return (
    <Navbar
      expand="md"
      id="topLevel"
      fixed="top"
      className={style.topLevelMenu}
    >
      <NavbarBrand className={style.logo} href="/">
        <img src={LogoWhite} alt="logo-white" />
      </NavbarBrand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="m-auto">
          <Nav className={style.menuMenu}>
            <Link to="/" className={style.menuItem}>
              Anasayfa
            </Link>
          </Nav>
          <Nav className={style.menuMenu}>
            <Link to="/kurumsal" className={style.menuItem}>
              Kurumsal
            </Link>
          </Nav>
          <Nav className={style.menuMenu}>
            <Link to="/projeler" className={style.menuItem}>
              Projeler
            </Link>
          </Nav>
          <Nav className={style.menuMenu}>
            <Link to="/banyo" className={style.menuItem}>
              Banyo
            </Link>
          </Nav>
          <Nav className={style.menuMenu}>
            <Link to="/ref" className={style.menuItem}>
              Referanslar
            </Link>
          </Nav>
          <Nav className={style.menuMenu}>
            <Link to="/iletisim" className={style.menuItem}>
              İletişim
            </Link>
          </Nav>
          <Nav className={style.menuMenu + " " + style.menuEngButton}>
            <Link to="/indexEng" className={style.menuItem}>
              ENG
            </Link>
          </Nav>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
export default Menubar
