import React from "react"
import { Link } from "gatsby"
import style from "./menubar.module.scss"
import LogoWhite from "../../img/atia-logo-white.svg"
import { Navbar, Nav, NavbarBrand } from "react-bootstrap"
import { useState, useEffect } from "react"

const MenubarEng = () => {
  const [scroll] = useState(50)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 50
      if (window.outerWidth > 766) {
        if (scrollCheck < scroll) {
          document.querySelector("#topLevel").style.backgroundColor = "#272727"
        }
        if (scrollCheck === false) {
          document.querySelector("#topLevel").style.backgroundColor =
            "rgba(0,0,0,0)"
        }
      } else {
        document.querySelector("#topLevel").style.backgroundColor = "#272727"
      }
    })
  })
  return (
    <Navbar
      expand="md"
      id="topLevel"
      fixed="top"
      className={style.topLevelMenu}
    >
      <NavbarBrand className={style.logo} href="/indexEng">
        <img src={LogoWhite} alt="logo-white" />
      </NavbarBrand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="m-auto">
          <Nav className={style.menuMenu}>
            <Link to="/indexEng" className={style.menuItem}>
              Home
            </Link>
          </Nav>
          <Nav className={style.menuMenu}>
            <Link to="/about" className={style.menuItem}>
              About
            </Link>
          </Nav>
          <Nav className={style.menuMenu}>
            <Link to="/projects" className={style.menuItem}>
              Projects
            </Link>
          </Nav>
          <Nav className={style.menuMenu}>
            <Link to="/bathroom" className={style.menuItem}>
              Bathroom
            </Link>
          </Nav>
          <Nav className={style.menuMenu}>
            <Link to="/refEng" className={style.menuItem}>
              References
            </Link>
          </Nav>
          <Nav className={style.menuMenu}>
            <Link to="/contact" className={style.menuItem}>
              Contact
            </Link>
          </Nav>
          <Nav className={style.menuMenu + " " + style.menuEngButton}>
            <Link to="/" className={style.menuItem}>
              TR
            </Link>
          </Nav>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
export default MenubarEng
